import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Adam | Full Stack Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'My Personal Site', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is ',
  name: 'Adam',
  subtitle: 'I am a Full Stack Developer',
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  // img: 'profile.jpg',
  img: 'profile.png',
  paragraphOne: 'I am a seasoned technical professional. I graduated from the University of Wisconsin-Madison with B.S. and M.S. degrees in Biomedical Engineering. More recently, I have acquired a passion and interest in Web application development, including formal training as a full-stack JavaScript developer via DevMountain\'s bootcamp curriculum for Full Stack Web Development. I enjoy problem solving in general, and solving problems from a computer science perspective in particular.',
  paragraphTwo: 'My Frontend Skills: HTML, CSS, Sass/SCSS, JavaScript, React, Redux, TypeScript',
  paragraphThree: 'My Backend Skills: Node.js, Express, C#.NET, PostgreSQL, T-SQL',
  paragraphFour: 'My Other Skills: Bash, Git, REST API, Jira, Agile/Scrum',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project-aprender.png',
    title: '¡Aprender!',
    info: 'Spanish language vocabulary builder for foreign language study',
    info2: 'Technologies Used: HTML, CSS, Sass, JavaScript, React (Hooks and Context API), Node.js, Express, PostgreSQL',
    url: 'http://aprender.tech',
    repo: 'https://github.com/awpala/aprender', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project-jam-sessions.png',
    title: 'Jam Sessions',
    info: 'Tinder clone for finding local musicians to jam with',
    info2: 'Technologies Used: HTML, CSS, Sass, JavaScript, React, Redux, Node.js, Express, PostgreSQL, Socket.io',
    url: 'http://www.jam-sessions.live',
    repo: 'https://github.com/tinder-for-musicians/jam-sessions', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project-clrs.png',
    title: 'CLRS',
    info: 'Implementation of canonical data structures and algorithms, as described in Introduction to Algorithms (3rd edition) by Cormen, Leiserson, Rivest, and Stein (CLRS)',
    info2: 'Technologies Used: JavaScript, Node.js',
    url: 'https://github.com/awpala/data-structures-and-algorithms',
    repo: 'https://github.com/awpala/data-structures-and-algorithms', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: '',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: 'Inquire about my work or my interests below...',
  btn: '@Adam',
  email: 'adam@adam-pala.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/adam-pala/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/awpala',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
